import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { MaskIcon } from '../Icons/Icons';

import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  openLoginModalRedux,
  openRegisterModalRedux,
  openRlcSelectorRedux,
} from '../../redux/modalSlice';
import BrandLogo from '../BrandLogo/BrandLogo';
import {
  Count,
  HeaderContainer,
  HeaderWrapper,
  IconContainer,
  LocalizationHeader,
  LocationContainer,
  LoggedOutButtonsContainer,
  LoginBtnWrapper,
  LogoContainer,
  NumberCircleContainer,
  SiteHeader,
  UserContainer,
  UserPart,
} from './HeaderStyle';

import { Skeleton } from '@mui/material';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTheme } from 'styled-components';
import Cookies from 'universal-cookie';
import { COLORS } from '../../constants/colors';
import { generateNavigation } from '../../constants/navItems';
import { AppThemeContext } from '../../contexts/ThemeContext';
import { saveEventV3 } from '../../utils/eventTracking';
import Button from '../NewButton/Button';

const BasicPopover = dynamic(() => import('../Popover/Popover'), {
  ssr: false,
});

import ProductSearchInput from '../ProductSearchInput/ProductSearchInput';

import { languageList } from '../../constants/languageList';
import { useAnalytics } from '../../contexts/AnalyticsProvider';
import { useLocale } from '../../contexts/LocalizationContext';
import { WE_USER_EVENT_THEME_TOGGLE } from '../../utils/we';
import CCFlag from '../CCFlag/CCFlag';
import MemodSubHeader from '../SubHeader/SubHeader';

import UserPopoverList from '../UserPopoverList/UserPopoverList';

const Link = dynamic(() => import('next/link'), {
  ssr: true,
});

function useScrollDirection() {
  const [scrollDirection, setScrollDirection] = React.useState<any>(null);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  return scrollDirection;
}

const UserIcon = () => {
  const router = useRouter();

  return (
    <UserContainer>
      <IconContainer>
        <MaskIcon
          url="/icons/profile-24.svg"
          selected={true}
          margin="0 0 0 0"
          onClick={() => {
            saveEventV3({
              category: 'header',
              action: 'click',
              label: 'user profile dropdown',
              properties: '',
              value: [],
              from: router,
            });
          }}
          height="24px"
          width="24px"
          color={COLORS.white}
        />
      </IconContainer>
      {/* <UserName>{user && user.first_name ? user.first_name : null}</UserName> */}
    </UserContainer>
  );
};

export const HeaderCartIcon = ({
  color,
  location,
}: {
  color?: string;
  location?: string;
}) => {
  const router = useRouter();
  const [numberOfCartItems, setNumberOfCartItems] = useState(0);

  const { cartItems } = useAppSelector((state) => state.cart);

  useEffect(() => {
    let quantity = 0;
    for (let i = 0; i < cartItems.length; i++) {
      quantity = quantity + cartItems[i].qty;
    }
    setNumberOfCartItems(quantity);
  }, [cartItems]);

  return (
    <IconContainer
      onClick={() => {
        saveEventV3({
          category: 'header',
          action: 'click',
          label: 'cart',
          properties: '',
          value: ['/cart'],
          from: router,
        });
      }}
    >
      {numberOfCartItems > 0 ? (
        <NumberCircleContainer
          style={{ right: location === 'mobile' ? '-6px' : '6px' }}
        >
          <Count>
            {numberOfCartItems > 9 ? <span>9+</span> : numberOfCartItems}
          </Count>
        </NumberCircleContainer>
      ) : null}
      <MaskIcon
        url="/icons/shopping-cart-24.svg"
        height="24px"
        width="24px"
        selected={true}
        color={color ?? COLORS.white}
        margin="0 0 0 0 "
      />
    </IconContainer>
  );
};

// eslint-disable-next-line react/display-name
const LoggedOut = memo(() => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  const { messages } = useLocale();
  const { login_msg, signup_msg } = messages || {};

  return (
    <>
      <LoggedOutButtonsContainer>
        <LoginBtnWrapper>
          <Button
            buttonTheme="grey"
            height="48px"
            width="120px"
            onClick={() => {
              saveEventV3({
                category: 'header',
                action: 'click',
                label: 'login',
                properties: '',
                value: [],
                from: router,
              });
              dispatch(openLoginModalRedux());
            }}
            ariaLabel="login"
          >
            {login_msg}
          </Button>
        </LoginBtnWrapper>
        <Button
          buttonTheme="primary"
          onClick={() => {
            saveEventV3({
              category: 'header',
              action: 'click',
              label: 'signup',
              properties: '',
              value: [],
              from: router,
            });
            dispatch(openRegisterModalRedux());
          }}
          height="48px"
          width="120px"
          ariaLabel="sign up"
        >
          {signup_msg}
        </Button>
      </LoggedOutButtonsContainer>
    </>
  );
});

// eslint-disable-next-line react/display-name
const LoggedIn = memo(() => {
  return (
    <BasicPopover
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      boxPadding={0}
      button={<UserIcon />}
      sx={{ borderRadius: '16px' }}
    >
      <UserPopoverList />
    </BasicPopover>
  );
});

interface HeaderProps {
  type?: string;
  headerColor?: 'translucent' | 'normal';
  children?: React.ReactElement;
  infinteHeader?: boolean;
}

const Header = (props: HeaderProps) => {
  const scrollDirection = useScrollDirection();
  const appTheme = useContext(AppThemeContext);
  const theme = useTheme();
  const router = useRouter();
  const { locale } = router;
  let { messages } = useLocale();

  const dispatch = useAppDispatch();
  const { isLoggedIn, isFetching, user, loginStatus } = useAppSelector(
    (state) => state.user
  );

  const navItems = useMemo(() => generateNavigation(messages), [messages]);
  const { cleverTap } = useAnalytics();

  const { locationDetails } = useAppSelector((state) => state.global);

  const handleThemeToggle = useCallback(() => {
    const cookies = new Cookies();
    if (appTheme?.theme === 'dark') {
      appTheme.changeTheme('light');
      cookies.set('theme', 'light', { maxAge: 1000000 });
    } else {
      appTheme?.changeTheme('dark');
      cookies.set('theme', 'dark', { maxAge: 1000000 });
    }
  }, [appTheme]);

  return (
    <HeaderWrapper>
      <HeaderContainer headerColor={props.headerColor ?? ''}>
        <SiteHeader infiniteHeader={props.infinteHeader ?? false}>
          <LogoContainer>
            <Link href="/" passHref>
              <a>
                <BrandLogo />
              </a>
            </Link>
          </LogoContainer>

          {props.type ? props.children : <ProductSearchInput type="web" />}
          {/* {width && width <= 1024 ? null : (
            <>
              <Link href="/store" passHref prefetch={false}>
                <NavButton>
                  {' '}
                  <MaskIcon
                    url="/icons/store-24.svg"
                    selected={true}
                    margin="0 0 0 0"
                    height="24px"
                    width="24px"
                  />{' '}
                  {store_msg}
                </NavButton>
              </Link>
              <div ref={categoriesRef} style={{ position: 'relative' }}>
                <NavButton onClick={() => setShowCategories((prev) => !prev)}>
                  {' '}
                  <MaskIcon
                    url="/icons/categories-24.svg"
                    selected={true}
                    margin="0 0 0 0"
                    height="24px"
                    width="24px"
                  />{' '}
                  {categories_msg}
                </NavButton>
                {showCategories && (
                  <Categories handleCategoriesClose={handleCategoriesClose} />
                )}
              </div>
            </>
          )} */}

          <UserPart>
            {props.type === 'about' ? null : locationDetails?.country_code ? (
              <LocationContainer>
                {/* <CountryListNav
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                /> */}
                <LocalizationHeader
                  onClick={() => {
                    saveEventV3({
                      category: 'header',
                      action: 'click',
                      label: 'rlc-button',
                      properties: '',
                      value: [],
                      from: router,
                    });
                    dispatch(openRlcSelectorRedux());
                  }}
                >
                  <CCFlag
                    countryCode={
                      locationDetails?.flag_country_code ||
                      locationDetails?.country_code
                    }
                    header={true}
                  />
                  {locationDetails.currency} &#x2022;{' '}
                  {languageList?.find((cur) => cur.locale === locale)?.name}
                </LocalizationHeader>
              </LocationContainer>
            ) : (
              <Skeleton height={70} width={70} />
            )}

            {props.type === 'about' ? null : (
              <IconContainer
                onClick={() => {
                  WE_USER_EVENT_THEME_TOGGLE(
                    {
                      previousTheme: theme.mode,
                      pageUrl: window.location.href,
                      currentTheme: theme.mode === 'dark' ? 'light' : 'dark',
                    },
                    cleverTap
                  );
                  saveEventV3({
                    category: 'header',
                    action: 'click',
                    label: 'theme',
                    properties: '',
                    value: [theme.mode === 'dark' ? 'light' : 'dark'],
                    from: router,
                    jsonData: {
                      old: theme.mode,
                      new: theme.mode === 'dark' ? 'light' : 'dark',
                    },
                  });
                  handleThemeToggle();
                }}
              >
                <MaskIcon
                  margin="0"
                  width="24px"
                  height="24px"
                  url={`/icons/${
                    appTheme?.theme === 'dark' ? 'moon' : 'star'
                  }.svg`}
                  selected={true}
                  color={COLORS.white}
                />
              </IconContainer>
            )}
            {isLoggedIn && user && (
              <>
                <Link href="/favourites" passHref prefetch={false}>
                  <a aria-label="favourties icon">
                    <IconContainer
                      onClick={() => {
                        saveEventV3({
                          category: 'header',
                          action: 'click',
                          label: 'favourites',
                          properties: '',
                          value: ['/favourites'],
                          from: router,
                        });
                      }}
                    >
                      <MaskIcon
                        margin="0"
                        width="24px"
                        height="24px"
                        url="/icons/favorite-24.svg"
                        selected={true}
                        color={COLORS.white}
                      />
                    </IconContainer>
                  </a>
                </Link>
              </>
            )}
            {props.type === 'cart' ||
            props.type === 'checkout' ? null : loginStatus === 'existing' &&
              user === undefined ? (
              <div>
                <IconContainer>
                  <Skeleton
                    height={'48px'}
                    width={'28px'}
                    sx={{ borderRadius: '50%' }}
                  />
                </IconContainer>
              </div>
            ) : (
              <>
                <Link href="/cart" passHref prefetch={false}>
                  <a aria-label="Cart icon">
                    <HeaderCartIcon />
                  </a>
                </Link>
              </>
            )}
            {props.type === 'cart' ||
            props.type === 'checkout' ? null : loginStatus === 'existing' &&
              user === undefined ? (
              <div style={{ display: 'flex', gap: '16px' }}>
                <Skeleton height={'48px'} width={'120px'} />
                <Skeleton height={'48px'} width={'120px'} />
              </div>
            ) : isLoggedIn ? (
              <LoggedIn />
            ) : (
              <LoggedOut />
            )}
          </UserPart>
        </SiteHeader>
      </HeaderContainer>
      {!(
        props.type === 'cart' ||
        props.type === 'checkout' ||
        props.type === 'user' ||
        props.type === 'about' ||
        props.type === 'sell-on-driffle' ||
        props.type === 'collections'
      ) &&
        navItems && (
          <MemodSubHeader
            navItems={navItems}
            scrollDirection={scrollDirection}
          />
        )}
    </HeaderWrapper>
  );
};

export default memo(Header);
