import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const SubHeaderWrapper = styled.div`
  width: 100%;
  height: 44px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.3s all ease;
  z-index: -1;
  // top: 74px;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;

export const SubHeaderBg = styled.div`
  width: 100%;
  height: 44px;
  position: absolute;
  background: ${COLORS.headerBg};
  opacity: 1;
`;
export const SubHeaderContainer = styled.div`
  max-width: 1728px;
  width: 100%;
  position: relative;
  display: flex;
  padding: 0 40px;
  margin: 0 auto;
  justify-content: space-between;
`;

export const SubHeaderContainerMenu = styled.div`
  display: flex;
  flex-direction: row;

  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
`;

export const SubHeaderItem = styled.div<{ selected: boolean }>`
  display: flex;
  font-size: 1rem;
  line-height: 1.25rem;
  position: relative;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-family: Onest-Medium;
  cursor: pointer;
  padding: 0 12px;
  white-space: nowrap;
  // ${(props) => (props.selected ? 'background: rgba(41, 41, 41)' : '')};
  // :hover {
  //   background: rgba(41, 41, 41);
  // }
`;

export const SubHeaderItemMarker = styled.div<{ selected: boolean }>`
  height: ${(props) => (props.selected ? '4px' : '0px')};
  width: 100%;
  background: ${(props) => props.theme.palette.primary.main};
  position: absolute;
  bottom: 0;
  transition: 0.15s;
`;

export const SecondaryNavBarWrapper = styled.div`
  max-width: 1188px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 36px;
  // gap: 24px;
  @media (max-width: 1240px) {
    margin: 0 40px;
  }
`;
interface NavbarOptionContainerInterface {
  index: number;
}
export const NavbarOptionContainer = styled.div<NavbarOptionContainerInterface>`
  position: absolute;
  top: 36px;

  box-sizing: border-box;
  // background: #2a2a2a;
  background: transparent;

  margin-left: 48px;
  // padding-top: 16px;
  min-height: 200px;
  left: 0;
  // width: 100%;
  width: fit-content;

  // width: fit-content;
  // display: grid;
  // grid-template-columns: 2fr 1fr;
  // gap: 52px;
  height: fit-content;
  @media (max-width: 1400px) {
    // padding-left: 52px;

    // padding-right: 25px;
    // gap: 0;
  }
  @media (max-width: ${WIDTH.laptopMax}) {
    // padding-left: 25px;
    // padding-right: 25px;
  }

  @media (min-width: 1728px) {
    // margin-left: 48px;
    margin-left: calc(((100vw - 1728px) / 2) + 40px);
  }
`;

export const NavbarOverlay = styled.div`
  background: ${(props) =>
    props.theme.mode === 'dark'
      ? props.theme.palette.background.bg2
      : props.theme.palette.black.main};
  opacity: 0.5;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 36px;
  z-index: -1;
  height: calc(100vh - 110px);
`;

export const OptionsSection = styled.div<{ columns?: number; index: number }>`
  padding: 24px 48px;
  // margin-top: 16px;
  margin-bottom: 0;
  // border-radius: 0px 0px 8px 8px;
  // margin-left: 0;
  // margin-right: 0;
  box-shadow: 0px 8px 40px 0px rgba(0, 0, 0, 0.48);
  // margin-right: auto;
  height: fit-content;
  //new
  width: fit-content;
  // width: 1728px;
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  justify-content: center;
  gap: 32px;
  // background: ${(props) => props.theme.palette.background.bg2};
  background: #161616;

  // max-height: 378px;
  // @media (max-width: 1600px) {
  //   gap: 40px;
  // }

  // @media (max-width: ${WIDTH.laptopMax}) {
  //   flex-direction: column;
  // }
`;

export const OptionsItemChildrenContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 88px;
  flex-grow: 1;
  // min-width: 1200px;
  // @media (max-width: ${WIDTH.laptopMax}) {
  //   justify-content: center;
  // }
`;

export const BannersSectionContainer = styled.div`
  height: fit-content;
  margin-right: 24px;
  margin-left: 24px;
`;

export const BannersTitle = styled.p`
  font-family: 'Neue Machina';
  font-style: normal;
  font-family: Onest-Bold;
  font-size: 20px;
  line-height: 24px;
  color: ${(props) => props.theme.palette.text.t1};
  margin-bottom: 24px;
`;

export const BannersContainer = styled.div`
  display: flex;
  // grid-template-columns: 1fr 1fr;
  height: fit-content;
  gap: 48px;
  align-items: center;
  & img {
    transition: 0.2s;
  }
`;

export const BannerImageWrapper = styled.div`
  position: relative;
`;

interface BannerImageBgPropsI {
  hoverBg: string;
}

export const BannerImageBg = styled.div<BannerImageBgPropsI>`
  width: 203px;
  height: 316px;
  border-radius: 8px;
  transform: skew(-10deg);
  background: linear-gradient(155.92deg, #5c5c5c 45.26%, #1e1e1e 89.47%);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${(props) => props.hoverBg};
    & > div {
      transform: skew(11deg) translate(-65px, -20px) scale(1.02);
    }
  }
  & > div {
    transform: skew(11deg) translate(-65px, -20px);
    left: 3px;
    top: -1px;
  }
`;

export const BannerImageContainer = styled.div`
  position: absolute;
  top: 0px;
  pointer-events: none;
  & > span {
    transform: skew(0deg) translate(-65px, -20px);
  }
`;

export const SmallArrow = styled.div`
  height: 16px;
  position: absolute;
  bottom: -25px;
  width: 16px;
  transform: rotate(45deg);
  background: ${(props) => props.theme.palette.background.bg1};
`;

export const ItemChildrenWrapper = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  flex-wrap: wrap;
  width: fit-content;
`;

export const ItemChildrenTitle = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  width: max-content;
  font: 0.875rem/1.125rem Onest-Bold;
  padding-bottom: 8px;
  width: 100%;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider.d1};
  margin-bottom: 12px;
  text-transform: uppercase;
`;

export const ItemChildrenContainer = styled.div<{
  count?: number;
}>`
  display: grid;
  max-height: calc(378px - 64px - 34px);
  grid-auto-flow: column;
  grid-template-columns: ${(props) =>
    props.count ? (props.count <= 6 ? '1fr' : '1fr 1fr') : '1fr'};
  grid-template-rows: repeat(6, 1fr);
`;

export const ItemChildrenOptions = styled.div`
  color: ${(props) => props.theme.palette.text.t1};
  cursor: pointer;
  font-size: 16px;
  padding: 12px;
  border-radius: 8px;
  width: 100%;
  :hover {
    // color: ${(props) => props.theme.palette.primary.main};
    background: ${(props) => props.theme.palette.background.bg3};
  }
`;

export const LoadMoreOption = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  text-decoration-color: ${(props) => props.theme.palette.text.t2};
  color: ${(props) => props.theme.palette.text.t1};
  padding: 12px;
  cursor: pointer;
  font-family: Onest-Bold;
  :hover {
    text-decoration-color: ${(props) => props.theme.palette.text.t1};
  }
`;
