import axios from 'axios';
import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { ProductSearchInterface } from '../../Interface/ProductSearchInterface';
import { apiConstants } from '../../constants/apiConstants';
import { getSearchABType } from '../../utils/abTest';
import { saveEventV3, trackEvent } from '../../utils/eventTracking';
import { getRecombeeSearchResults } from '../../utils/recombee';

interface SearchInterface {
  list: Array<ProductSearchInterface>;
  recommId: string;
}

function useSearch() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState<SearchInterface | null>(null);
  const [searchedText, setSearchedText] = useState<string>(''); // this stores the string value that has been searched
  const [searchText, setSearchText] = useState<string>('');
  // const [searchType, setSearchType] = useState<'driffle' | 'recombee' | null>(
  //   null
  // ); // this stores the type of search that has been performed

  useEffect(() => {
    if (
      router.pathname.includes('/store') &&
      router.query.q &&
      typeof router.query.q === 'string'
    ) {
      setSearchText(router.query.q);
    }
  }, [router]);

  const searchEvent = useCallback(
    (value: string) => {
      saveEventV3({
        category: 'header',
        action: 'input_text',
        label: 'search',
        properties: getSearchABType(),
        value: [value],
        from: router,
      });
    },
    [router]
  );

  const debounceSearchEvent = useCallback(debounce(searchEvent, 1500), []);

  const fetchMeiliSearchResults = useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const response = await axios.get(apiConstants.search + '/search', {
          withCredentials: true,
          params: {
            q: value,
          },
        });
        if (response.status === 200) {
          setResults({ list: response.data.data, recommId: '' });
          setLoading(false);
          if (response.data.data.length === 0) {
            saveEventV3({
              action: 'input_text',
              category: 'header',
              label: 'search',
              properties: 'no-results',
              value: [value],
              from: router,
            });
          }
        }
      } catch (error) {
        setResults({
          list: [],
          recommId: '',
        });
        setLoading(false);
      }
    },
    [router]
  );

  //   const fetchDBSearchResults = useCallback((value: string) => {}, []);

  const fetchRecombeeSearchResults = useCallback(
    async (value: string) => {
      try {
        setLoading(true);
        const res = await getRecombeeSearchResults(value);
        if (res) {
          if (res.list.length === 0) {
            saveEventV3({
              action: 'input_text',
              category: 'header',
              label: 'search',
              properties: 'no-results',
              value: [value],
              from: router,
            });
          }
          setResults({
            list: res.list,
            recommId: res.id,
          });
          setLoading(false);
        }
      } catch (error) {
        fetchMeiliSearchResults(value);
      }
    },
    [fetchMeiliSearchResults, router]
  );

  const fetchSearchResult = useCallback(
    (value: string) => {
      setSearchedText(value);
      if (value === '') {
        setResults(null);
        return;
      }
      const eventData = {
        event: 'search',
        search_term: value,
      };
      trackEvent('gtm_event', { eventData });
      debounceSearchEvent(value);

      if (getSearchABType() === '2') {
        fetchRecombeeSearchResults(value);
        return;
      }
      if (getSearchABType() === '1') {
        fetchMeiliSearchResults(value);
        return;
      }
    },
    [debounceSearchEvent, fetchMeiliSearchResults, fetchRecombeeSearchResults]
  );

  const debounceOnChange = useCallback(debounce(fetchSearchResult, 500), [
    fetchSearchResult,
  ]);

  useEffect(() => {
    debounceOnChange(searchText);
  }, [searchText, debounceOnChange]);

  return {
    loading,
    results,
    searchText,
    searchedText,
    setSearchText,
    setResults,
    setSearchedText,
  };
}

export default useSearch;
